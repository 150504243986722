import React, { useState, useEffect } from "react";
import "../Css/common.css";
import Web from "../Components/Web";
import Web1 from "../Components/Web1";
import Mark from "../Components/Mark";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Slider from "react-slick";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Creative(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    width: 540,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  // const [data13, setdata13] = useState([]);

  // const [data14, setdata14] = useState([]);

  // const [data18, setdata18] = useState([]);

  // const [data19, setdata19] = useState([]);

  // useEffect(() => {
  //   datas();
  // }, []);

   const api_url = "https://api.icreatedegreecollege.com";

  // const datas = () => {
  //   axios
  //     .post("https://api.icreatedegreecollege.com/api/web/landing/getitems", {}, {})
  //     .then(
  //       (res) => {
  //         if (res.status === 200) {
  //           setdata13(res.data.portfolioHeadingResult[0]);

  //           setdata14(res.data.portfolioMobileResult);

  //           setdata18(res.data.portfoliowebResult);

  //           setdata19(res.data.portfolioMarketResult);

  //         }
  //       },
  //       (error) => {
  //         if (error.response && error.response.status === 400) {
  //           toast(error.response.data.message);
  //         }
  //       }
  //     );
  // };


  console.log([props])

  return (
    <div>
    
    </div>
  );
}

export default Creative;
