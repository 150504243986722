import React, { useState, useEffect } from "react";
import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Row, Col, Card, CardBody, Button, Input, Label } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import { Table } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import { URL } from "../Apiurl";
import { useNavigate } from "react-router";
import { CSVLink } from "react-csv";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Adddetails = () => {
  const [showResults, setShowResults] = React.useState(false);
  const showfield = () => setShowResults(true);
  const hidefield = () => setShowResults(false);

  let navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [carddetails, setcarddetails] = useState([]);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);

  const [details, setdetails] = useState([]);
  const [file, setFile] = useState();
  const [data1, setdata1] = useState([]);
  const [data, setdata] = useState([]);
  const [data2, setdata2] = useState([]);
  const [search, setsearch] = useState("");

  const [form, setform] = useState([]);
  const [form1, setform1] = useState([]);
  const [userInCsv, setuserInCsv] = useState([]);
  const [image, setimage] = useState("");

  const [listPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = details.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(details.length / listPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // add
  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };
  const handlechange12 = (e) => {
    let myUser = { ...form1 };
    myUser[e.target.name] = e.target.value;
    setform1(myUser);
  };

  // edit
  const ehandlechange = (e) => {
    let myUser = { ...data };
    myUser[e.target.name] = e.target.value;
    setdata(myUser);
  };

  useEffect(() => {
    addCards();
  }, []);

  const headers = [
    { label: "Name", key: "name" },
    { label: "Mobile", key: "phone" },
    { label: "Email", key: "email" },
    { label: "Service", key: "service" },
    { label: "Date", key: "updatedAt" },
  ];
  const csvReport = {
    filename: "Culvert Report.csv",
    // headers: headers,
    data: details,

  };

  const getAllAddDetails = () => {
    var token = sessionStorage.getItem("token");
    axios
      .post(
        "https://api.icreatedegreecollege.com/api/admin/searchfilter/gettabledata",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log(res.data.searchResult);
        setdetails(res.data.searchResult);
        setuserInCsv(res.data.searchResult);
      });
  };

  const addCards = () => {
    var token = sessionStorage.getItem("token");
    var params = {
      formdtat: form.fromDate,
      toDate: form.toDate,
      service: form.service,
    };
    axios
      .post(
        "https://api.icreatedegreecollege.com/api/admin/searchfilter/gettabledata",
        params,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log("success");

        setdetails(res.data.searchResult);
      });
  };

  const cardsSubmit = (e) => {
    e.preventDefault();
    addCards();
  };

  const cardsEdit = (e) => {
    e.preventDefault();
    editCards();
  };

  const editCards = () => {
    var token = sessionStorage.getItem("token");
    var id = data._id;
    var formData = new FormData();
    formData.append("summaryNumber", data.summaryNumber);
    formData.append("summaryImg", image[0]);
    formData.append("shapeCut", data.shapeCut);
    formData.append("totalEstWt", data.totalEstWt);
    formData.append("color", data.color);
    formData.append("clarity", data.clarity);
    formData.append("comment", data.comment);
    formData.append("description", data.description);
    console.log(data.description);
    axios
      .put(URL.editcards + "/" + id, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then(
        (res) => {
          if (res.status === 200) {
            console.log("upaded succes");
            toast("Updated successfully ");
            // toggle2()
            getAllAddDetails();
            handleClose1();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const getpopup = (data) => {
    setdata(data);

    handleShow1();
  };
  const getpopup1 = (val) => {
    handleShow2();
    setdata2(val);
    console.log("value", val._id);
    getAllAddDetails(val);
  };

  const cardDetails = (summariesResult) => {
    var token = sessionStorage.getItem("token");
    var paras = {
      _id: summariesResult._id,
    };
    console.log(paras);
    axios
      .post(URL.getonecards, paras, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          navigate("/view-details");
          setcarddetails(res.data);
        }
      });
  };
  const handleView = (d) => {
    setcarddetails(d);
    cardDetails(d);
    sessionStorage.setItem("viewdetails", JSON.stringify(d));
    navigate("/view-details");
  };

  const manageDelete = (searchResult) => {
    const confirmBox = window.confirm("Do you really want to Delete?");
    if (confirmBox === true) {
      deleteUser(searchResult);
    }
  };

  const deleteUser = (searchResult) => {
    var token = sessionStorage.getItem("token");
    const data1 = searchResult._id;
    console.log(data1);
    axios
      .delete(
        "https://api.icreatedegreecollege.com/api/admin/searchfilter/removeabledata" +
          "/" +
          data1,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            addCards();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const handleImageUpload = (e) => {
    const file = e.target.files;
    console.log(file);
    var ext = file[0].name.split(".").pop();
    var type = ext;
    console.log(type);
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setimage(e.target.files);
      console.log(e.target.files);
    } else {
      e.target.value = null;
      toast("file format not supported.Pls choose jpg/jpeg/png");
    }
  };

  const downloadImage = () => {};

  return (
    <div>
      {" "}
      <Box
        sx={{ display: "flex" }}
        style={{ background: "#ebeaea", padding: "111px 0 0 0" }}
        className="cardmrg"
      >
        <Drawer></Drawer>
        <CssBaseline />
        <Box
          component="main"
          style={{ background: "#ebeaea" }}
          sx={{ flexGrow: 2, p: 4 }}
        >
          {showResults ? (
            <Card className="cardstyle mb-4">
              <div className="row" style={{ padding: "20px 0px 0px 15px" }}>
                {/* <form> */}
                <div className="col-4">
                  <label className="mb-2">Courses</label>
                  <select className="form-control" name="Courses" value={form.Courses} id="signinPassword2" placeholder="Email Id" onChange={(e) => { handlechange(e); }} aria-label="Password" required >
                          <option value="">Select Course</option>
                          <option
                            className="form-control"
                            value="BBA"
                          >
                            BBA
                          </option>

                          <option
                            className="form-control"
                            value=" BBA-CAT"
                          >
                            BBA-CAT
                          </option>

                          <option
                            className="form-control"
                            value="BBA-EDP"
                          >
                            BBA-EDP
                          </option>
                          <option
                            className="form-control"
                            value=" BBA-SAP"
                          >
                            BBA-SAP
                          </option>
                          <option
                            className="form-control"
                            value=" BBA-GME"
                          >
                            BBA-GME
                          </option>

                          <option
                            className="form-control"
                            value=" BBA-ACCA"
                          >
                            BBA-ACCA
                          </option>
                          <option
                            className="form-control"
                            value="  BBA-CWE"
                          >
                            BBA-CWE
                          </option>

                          <option
                            className="form-control"
                            value=" B.COM-COMPUTERS"
                          >
                            B.COM-COMPUTERS
                          </option>
                          <option
                            className="form-control"
                            value=" BBA Analytics"
                          >
                            BBA Analytics
                          </option>
                          <option
                            className="form-control"
                            value=" Food & Nutrition"
                          >
                            Food & Nutrition
                          </option>
                          <option
                            className="form-control"
                            value="Hotel Management"
                          >
                            Hotel Management
                          </option>
                        </select>

                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col">
                      <label className="mb-2">From Date</label>
                      <input
                        className="form-control"
                        name="fromDate"
                        type="date"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </div>
                    <div className="col">
                      <label className="mb-2">To Date</label>
                      <input
                        className="form-control"
                        name="toDate"
                        type="date"
                        onChange={(e) => {
                          handlechange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    onClick={cardsSubmit}
                    className="btn btn-success"
                    style={{ marginTop: "35px" }}
                  >
                    Search
                  </button>
                </div>
                {/* </form> */}
              </div>
            </Card>
          ) : (
            ""
          )}
          <Row>
            <Col lg={12}>
              <Card className="cardstyle">
                <CardBody>
                  <div className="row">
                    <div className="col">
                      <h5>Leads</h5>
                    </div>
                    <div className="col">
                      <span style={{ float: "right" }}>
                        {" "}
                        <button
                          className="btn btn filter mb-3"
                          onClick={() => {
                            setShowResults(!showResults);
                          }}
                        >
                          {" "}
                          <i class="fa fa-filter mr-2" aria-hidden="true"></i>
                          Filter
                        </button>
                      </span>
                    </div>
                  </div>

                  <div>
                    <div style={{ float: "right" }}>
                      <input
                        type="search"
                        placeholder="search..."
                        className="form-control"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setsearch(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ float: "left" }}>
                 

                    <CSVLink {...csvReport}>
                      <button
                        class="btn btn-primary btn pdf-view-btn addbtn export"
                        type="submit"
                      >
                        Export
                      </button>
                    </CSVLink>
                  </div>

                  <div  className="table-responsive mt-3">
                    <Table
                      id="table-to-xls"
                      bordered
                      className="table-centered datatable dt-responsive nowrap "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead
                        className="thead-light"
                        style={{ background: "#1562a0", color: "white" }}
                      >
                        <tr>
                          <th>Sl.no</th>
                          <th style={{ width: "130px" }}>Date</th>
                          <th>Full Name</th>
                          <th>location</th>
                          <th>Mobile</th>
                          <th>Email</th>
                          <th>Courses</th>

                          

                         
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists
                          .filter((searchResult) => {
                         
                            if (search === !null) {
                              return searchResult;
                            } else if (
                              searchResult.name
                                .toUpperCase()
                                .includes(search.toUpperCase())
                            ) {
                              return searchResult;
                            } else if (
                              searchResult.service
                                .toUpperCase()
                                .includes(search.toUpperCase())
                            ) {
                              return searchResult;
                            }
                          })
                          .map((searchResult, key) => (
                            <tr key={key}>
                              <td>{(pageNumber - 1) * 10 + key + 11}</td>
                              <td>
                                {/* <Moment format="DD/MM/YYYY"> */}
                                {searchResult.dateTime}
                                {/* </Moment> */}
                              </td>
                              <td>{searchResult.name}</td>
                              <td>{searchResult.location}</td>
                              <td>{searchResult.phone}</td>
                              <td>{searchResult.email}</td>
                              <td>{searchResult.service}</td>
                             
                              
                              {/* <td>{summariesResult.clarity}</td> */}
                              {/* <td><img src={"http://103.171.181.73:5011/" + summariesResult.softcopy} style={{ width: "100px", height: "90px" }} /></td>

                              <td onClick={() => getpopup1(summariesResult)}> <img src={"http://103.171.181.73:5011/" + summariesResult.qrcode} style={{ width: "100px", height: "90px" }} /></td> */}

                              {/* <Link
                                  to="#"
                                  className="mr-3 text-success"
                                  id={"edit" + key}
                                  onClick={() => { getpopup(summariesResult) }}
                                >
                                  <ModeEditIcon />
                                </Link>
                                <UncontrolledTooltip
                                  target={"edit" + key}
                                  placement="top"
                                >
                                  Edit
                                </UncontrolledTooltip>
                                <Link
                                  to="#"
                                  className="mr-3 text-primary"
                                  id={"view" + key}
                                  onClick={() => { handleView(summariesResult) }}
                                >
                                  <RemoveRedEyeIcon />
                                </Link>
                                <UncontrolledTooltip
                                  target={"view" + key}
                                  placement="top"
                                >
                                  view
                                </UncontrolledTooltip>
                                <Link
                                  to="#"
                                  className="text-danger"
                                  id={"delete" + key}
                                  onClick={() => { manageDelete(summariesResult._id) }}
                                >
                                  <DeleteOutlineIcon></DeleteOutlineIcon>
                                </Link>
                                <UncontrolledTooltip
                                  target={"delete" + key}
                                  placement="top"
                                >
                                  Delete
                                </UncontrolledTooltip> */}

                              <td>
                                {" "}
                                <DeleteForeverIcon
                                  onClick={() => {
                                    manageDelete(searchResult);
                                  }}
                                  style={{ fontSize: "30px", color: "red" }}
                                />
                              </td>

                              <div className="mt-4">
                                {/* <span className="adminbtnbad"><a className="btn adminbtns" onClick={() => { getpopup(summariesResult) }}> <ModeEditIcon className="text-success" /></a></span>
                            <span className="adminbtnbad"><a className="btn adminbtns2"  onClick={() => { handleView(summariesResult) }}> <RemoveRedEyeIcon className=" text-primary" /></a></span>
                            <span className="adminbtnbad"><a className="btn adminbtns3" onClick={() => { manageDelete(summariesResult._id) }}> <DeleteOutlineIcon className="text-danger"/></a></span> */}
                              </div>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    {/* 
                    <div style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                      />
                    </div> */}
                    <div className="mt-3" style={{ float: "right" }}>
                      {/* <Stack spacing={2}> */}
                      <a style={{ cursor: "pointer" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </a>
                      {/* </Stack> */}
                    </div>

                    <Modal
                      size="lg"
                      show={show}
                      onHide={handleClose}
                      className="modalpad"
                    >
                      <Modal.Header>
                        <Modal.Title>Add Cards Data </Modal.Title>
                        <a onClick={handleClose}>
                          {" "}
                          <i class="fa fa-times cros" aria-hidden="true"></i>
                        </a>
                      </Modal.Header>
                      <Modal.Body className="modalbod">
                        <form onSubmit={cardsSubmit}>
                          <div className="row">
                            <div className="col">
                              <Label>Summary No :</Label>
                              <Input
                                type="text"
                                pattern="^[\w'\-,.][^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                placeholder="Summary No"
                                maxlength="30"
                                className="form-control"
                                name="summaryNumber"
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                                required
                              />

                              <Label className="mt-2">Shape/cut :</Label>
                              <Input
                                type="text"
                                pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                placeholder="Shape/cut"
                                maxlength="30"
                                className="form-control"
                                name="shapeCut"
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                                required
                              />

                              <Label className="mt-2">Total Est. Wt. :</Label>
                              <Input
                                type="text"
                                pattern="^[\w'\-,.][^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                placeholder="Total Est. Wt."
                                maxlength="30"
                                className="form-control"
                                name="totalEstWt"
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                                required
                              />
                            </div>
                            <div className="col">
                              <Label>Color :</Label>
                              <Input
                                type="text"
                                pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                maxlength="30"
                                placeholder="Color"
                                className="form-control"
                                name="color"
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                                required
                              />
                              <Label className="mt-2">Clarity :</Label>
                              <Input
                                type="text"
                                pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                placeholder="Clarity"
                                maxlength="30"
                                className="form-control"
                                name="clarity"
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                                required
                              />
                              <Label className="mt-2">Comments :</Label>
                              <Input
                                type="text"
                                placeholder="Comments"
                                maxlength="100"
                                className="form-control"
                                name="comment"
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                                required
                              />
                            </div>
                          </div>
                          <Label className="mt-2">Image :</Label>
                          <Input
                            type="file"
                            formcontrolname="uploadFile"
                            className="form-control"
                            name="summaryImg"
                            onChange={(e) => {
                              handleImageUpload(e);
                            }}
                            //  onChange={(e) => handleImageUpload(e); {FilehandleChange}}

                            required
                          />

                          <Label className="mt-2">Description :</Label>
                          <textarea
                            type="text"
                            placeholder="Enter Description"
                            maxlength="137"
                            className="form-control"
                            name="description"
                            onChange={(e) => {
                              handlechange(e);
                            }}
                            required
                          />
                          <div style={{ float: "right" }} className="mt-3">
                            <Button
                              className="mr-3 bg-danger"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button color="success" type="submit">
                              Submit
                            </Button>
                          </div>
                        </form>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      size="lg"
                      show={show1}
                      onHide={handleClose1}
                      className="modalpad"
                    >
                      <Modal.Header>
                        <Modal.Title>Edit Cards Data </Modal.Title>
                        <a onClick={handleClose1}>
                          {" "}
                          <i class="fa fa-times cros" aria-hidden="true"></i>
                        </a>
                      </Modal.Header>
                      <Modal.Body className="modalbod">
                        <form onSubmit={cardsEdit}>
                          <div className="row">
                            <div className="col">
                              <Label>Summary No :</Label>
                              <Input
                                type="text"
                                pattern="^[\w'\-,.][^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                placeholder="Summary No"
                                maxlength="30"
                                value={data.summaryNumber}
                                className="form-control"
                                name="summaryNumber"
                                onChange={(e) => {
                                  ehandlechange(e);
                                }}
                                required
                              />

                              <Label className="mt-2">Shape/cut :</Label>
                              <Input
                                type="text"
                                pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                placeholder="Shape/cut"
                                maxlength="30"
                                value={data.shapeCut}
                                className="form-control"
                                name="shapeCut"
                                onChange={(e) => {
                                  ehandlechange(e);
                                }}
                                required
                              />

                              <Label className="mt-2">Total Est. Wt. :</Label>
                              <Input
                                type="text"
                                pattern="^[\w'\-,.][^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                placeholder="Total Est. Wt."
                                maxlength="30"
                                value={data.totalEstWt}
                                className="form-control"
                                name="totalEstWt"
                                onChange={(e) => {
                                  ehandlechange(e);
                                }}
                                required
                              />
                            </div>
                            <div className="col">
                              <Label>Color :</Label>
                              <Input
                                type="text"
                                pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                placeholder="Color"
                                maxlength="30"
                                value={data.color}
                                className="form-control"
                                name="color"
                                onChange={(e) => {
                                  ehandlechange(e);
                                }}
                                required
                              />
                              <Label className="mt-2">Clarity :</Label>
                              <Input
                                type="text"
                                pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                placeholder="Clarity"
                                maxlength="30"
                                value={data.clarity}
                                className="form-control"
                                name="clarity"
                                onChange={(e) => {
                                  ehandlechange(e);
                                }}
                                required
                              />
                              <Label className="mt-2">Comments :</Label>
                              <Input
                                type="text"
                                placeholder="Comments"
                                maxlength="100"
                                value={data.comment}
                                className="form-control"
                                name="comment"
                                onChange={(e) => {
                                  ehandlechange(e);
                                }}
                                required
                              />
                            </div>
                          </div>
                          <Label className="mt-2">Image :</Label>
                          <Input
                            type="file"
                            className="form-control"
                            pattern="/.(jpg|jpeg|png|gif)$/"
                            name="summaryImg"
                            // onChange={FilehandleChange}
                            onChange={(e) => {
                              handleImageUpload(e);
                            }}
                            required
                          />

                          <Label className="mt-2">Description :</Label>
                          <textarea
                            type="text"
                            placeholder="Enter Description"
                            maxlength="137"
                            value={data.description}
                            className="form-control"
                            name="description"
                            onChange={(e) => {
                              ehandlechange(e);
                            }}
                            required
                          />
                          <div style={{ float: "right" }} className="mt-3">
                            <Button
                              className="mr-3 bg-danger"
                              onClick={handleClose1}
                            >
                              Cancel
                            </Button>
                            <Button color="success" type="submit">
                              Submit
                            </Button>
                          </div>
                        </form>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      show={show2}
                      onHide={handleClose2}
                      className="modalpad"
                    >
                      <Modal.Header>
                        <Modal.Title>Qr Code</Modal.Title>{" "}
                        <a onClick={handleClose2}>
                          {" "}
                          <i class="fa fa-times cros" aria-hidden="true"></i>
                        </a>
                      </Modal.Header>
                      <Modal.Body className="modalbod">
                        <img
                          src={"https://api.icreatedegreecollege.com/" + data2.qrcode}
                          style={{ width: "100%", height: "400px" }}
                          alt="image"
                          className="imgstyle"
                        />
                        <button
                          onClick={downloadImage}
                          target="_blank"
                          className="btn btn form-control mt-4 bg-dark text-white"
                        >
                          <i
                            class="fa fa-arrow-circle-down mr-1"
                            aria-hidden="true"
                          ></i>{" "}
                          Download
                        </button>
                      </Modal.Body>
                    </Modal>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Paper elevation={3}></Paper> */}
        </Box>
      </Box>
      <ToastContainer />
    </div>
  );
};

export default Adddetails;
