import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Slider from "react-slick";
import icreatelogo from '../assets/images/icreatelogo.png';

// import logo from '../assets/images/logo.png'
import author1 from "../assets/images/blog/author-1.jpg";
import author2 from "../assets/images/blog/author-2.jpg";
import author3 from "../assets/images/blog/author-3.jpg";
import author4 from "../assets/images/blog/author-4.jpg";
// import author5 from "../assets/images/blog/author-5.jpg";
import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';
import "../assets/css/style.css";
import DigitalRaizpdf from "../assets/images/newimgs/1. Company Profile Final One.pdf";
import DigitalraizPvt from "../assets/images/newimgs/digitalraiz comany portfilo.pdf";
import { NavLink, useNavigate } from "react-router-dom";
import { PlayCircle } from '@mui/icons-material';

function Header() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [form, setform] = useState({
    name: "",
    phone:"",
    email: "",
    Courses: "BBA",
    location: ""
  });


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [sticky, setSticky] = useState("");


  useEffect(() => {
    const find = localStorage.getItem("finds")
    if (find == null) {
      handleShow();
    }

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);

    };
  }, []);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 200 ? "header-area sticky" : "";
    setSticky(stickyClass);
    console.log(stickyClass);
  };

  const classes = `${sticky}`;


  const settings1 = {


    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    width: 500,

    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    }]
  };

  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  }

  const addUsers = () => {
    var params = {
      name: form.name,
      phone: form.phone,
      email: form.email,
      service: form.Courses,
      location: form.location
    }
    axios.post("https://api.icreatedegreecollege.com/api/user/leads/addLead", params, {

    }).then((res) => {
      console.log("success")
      toast.success("Added Successfully. Your data received, Our team will get back to you soon!");
      handleClose();
      navigate('/thankyou')
    })

  }

  const usersSubmit = (e) => {
    e.preventDefault();
    addUsers();
  };

  return (
    <div>
      <header className={classes} id="header-area">
        <nav className="navbar navbar-expand-md fixed-top">
          <div className="container">
            <div className='row' style={{ width: "100%" }}>
              <div className='col-3'>

                <div className="pict"><NavLink to="/" className="navbar-brand" >
                  <img src={icreatelogo} className="img-fluid moblogoimg" alt="Img" id='pict' width={150} />
                </NavLink></div>
              </div>
              <div className='col-9'>
                {/* <a className="btn themes-btn btn-block headbtn" style={{border:"none",background:"none"}}><a  className=''><i class="fa fa-phone mr-1" aria-hidden="true"></i>+91 9494613601</a></a> */}
                <div className='headtab' style={{ float: "right" }}>

                  {/* <p> <i class="fa fa-phone mr-1" aria-hidden="true"></i>+91 9494613601</p> */}
                  <div className='row mt-3 mobtab' >
                    <div className='col-4 mobileslider' style={{ marginTop: "10px" }}>
                      <a href='tel:+91 83 67 215 215' className='contactnumber '> <i class="fa fa-phone mr-1 " aria-hidden="true"></i>+91 83 67 215 215</a>
                    </div>

                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 headerbtnsm'>

                      <div className="control-form">
                        <a href='https://www.instagram.com/icreate12/' target="_blank">
                          <button className="btn themes-btn btn-block headbtn" id='btn2'><a className='btna'>icreate12 <i class="fa fa-instagram" aria-hidden="true"></i></a></button>
                        </a>
                      </div>


                    </div>

                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4'>
                      <div className="control-form btnmar ">
                        <a href='https://www.icreateedu.in/' target="_blank">
                          <button className="btn theme-btn btn-block headbtn1"><span className='btna text-white' >www.icreateedu.in<i class="fa fa-arrow-right ml-1" aria-hidden="true"></i></span></button>
                        </a>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </nav>

      </header>

      <Modal

        size="lg"
        show={show}

        backdrop="static"
        keyboard={false}
        className="mt-5"
      >
        {/* <div onClick={handleClose}>
                <i class="fa fa-times modalstyle " aria-hidden="true"></i>
              </div> */}
        <div className="row login-page ">
          <div className="col-md-5 mobileslider cross">
            <div className=' login-side-des'>
              <div className="container-fluid">
                <div className="login-side-block">
                  <a href=""><img src={icreatelogo} alt="Logo" width={200} /></a>
                  <div className="login-reviews">
                    <div className="review-details-content">
                      <div className="owl-carousel review_details" id="review_details-2">
                        <Slider {...settings1} >
                          <div className="item">
                            <p>"One of fantastic college 🎉and management 👍👍best education ✍️"</p>
                            {/* <h5>Sravani Reddy</h5>
                            <h6>Vice Princile of Dr.Narayana group of institutions</h6> */}
                          </div>
                          {/* End review item */}
                          {/* Start review item */}
                          <div className="item">
                            <p>"  I create business school 🏫 is very helpful to my career feel really motivated when I am in Business School. The school has a very 💪strong motivating and performing 💯atmosphere and will help its students progress in their choosen career paths.and teacher are very good 💯 😊"</p>
                            {/* <h5>Ganesh</h5>
                            <h6>Chairmen of TaxiGk</h6> */}
                          </div>
                          {/* End review item */}
                          <div className="item">
                            <p>"One of the finest college for commerce and management studies. This college literally shows how studies correlates with real time .Thank u to all the experienced faculties and the management for their support.I personally recommend to join my upcoming generation to choose a college like this."</p>
                            {/* <h5>Abdullah</h5>
                            <h6>CEO of Iotroncs</h6> */}
                          </div>
                          {/* Start review item */}
                          <div className="item">
                            <p>"feel really motivated when I am in Business School. The school has a very strong motivating and performing atmosphere and will help its students progress in their choosen career paths."</p>
                            {/* <h5> Bhupesh </h5>
                            <h6>Md of SLN Services</h6> */}
                          </div>
                          {/* End review item */}
                          {/* Start review item */}

                          {/* End review item */}
                          {/* Start review item */}
                          <div className="item">
                            <p>"Faculty is very supportive and they creates a positive vibes with their attention and inspire the students"</p>
                            {/* <h5>Bala Koteshwara Rao</h5>
                            <h6>MD of Thrilokmart</h6> */}
                          </div>

                          {/* <div className="item">
                            <p>"They procure a Very Good team in icreate and web development. I recommend my customers to join hands with icreate"</p>
                            <h5>Sai Kiran</h5>
                            <h6>MD of Vedithtech</h6>
                          </div>
                          <div className="item">
                            <p>"Good quality best team members in time pra project submission.......Thank You icreate."</p>
                            <h5>Manikanta</h5>
                            <h6>Chairmen of SMV Chemical</h6>
                          </div>
                          <div className="item">
                            <p>"icreate developed my app name SIFALO, within time at a low cost. I suggested this company for mobile applications. "</p>
                            <h5>Khalil Ahmed</h5>
                            <h6>Somalia VP of Sifalo</h6>
                          </div>
                          <div className="item">
                            <p>"We were truly impressed! The way icreate developed my mobile application in the name of EXMOB, with less cost, on-time delivery, and finally, the way of conducting meetings and gathering the requirement was excellent. Thank you so much, icreate team."</p>
                            <h5>Nawaz</h5>
                            <h6>Chairmen of EXMOB</h6>
                          </div>
                          <div className="item">
                            <p>"One of the best digital marketing agencies to generate potential leads through google ads is icreate. Good Job Done."</p>
                            <h5>Nawaf</h5>
                            <h6>UAE</h6>
                          </div>
                          <div className="item">
                            <p>"We have hired them for our Mobile application development work,
                              They are productive and punctual in work they have completed our new mobile application very quickly. I truly recommend the icreate for abroad clients."</p>
                            <h5>Branty Bruno</h5>
                            <h6>France CEO of Decohindi</h6>
                          </div> */}
                        </Slider>
                      </div>
                    </div>
                    <p className='visible' onClick={handleClose}>.</p>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-7 pt-3">
            <div className="container">

              <a href="" className="res-logo"><img src={icreatelogo} alt="Logo" width={150} /></a>
              <div className="login-form modheight">
                <div className="login-form-head ">
                  <h2 ><span className='h2txt'>Welcome to</span> <span>icreate College</span></h2>
                  <p>Fill out the form to get started..</p>
                </div>
                <form onSubmit={usersSubmit}>
                  <div className="form-group">
                    <label className="form-label"
                      htmlFor="signinEmail"
                    > <span className='text-danger'>*</span>Full Name</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-user-o" />
                      </div>
                      <input type="text" pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" className="form-control" name="name" value={form.name} onChange={(e) => { handlechange(e); }} id="signinEmail" placeholder="Full Name" aria-label="Email address" required />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword">
                      <span className='text-danger'>*</span> Mobile
                    </label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-mobile" />
                      </div>
                      <input type="text" minlength="10" maxlength="10" pattern="\d{10}" className="form-control" name="phone" value={form.phone} id="signinPassword" onChange={(e) => { handlechange(e); }} placeholder="Mobile No..." aria-label="Password" required />
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword2"> Email Id</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <span className="fa fa-envelope-o" />
                      </div>
                      <input type="email" pattern="(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)" className="form-control" value={form.email} name="email" id="signinPassword2" onChange={(e) => { handlechange(e); }} placeholder="Email Id" aria-label="Password" required />
                    </div>
                  </div> */}
                  <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword2"><span className='text-danger'>*</span>Course </label>
                    <div className="input-group">
                      <div className="input-icon">

                        <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                      </div>

                      <select className="form-control" name="Courses" value={form.Courses} id="signinPassword2" placeholder="Email Id" onChange={(e) => { handlechange(e); }} aria-label="Password" required >
                        <option value="">Select Course</option>
                        <option
                          className="form-control"
                          value="BBA"
                        >
                          BBA
                        </option>

                        <option
                          className="form-control"
                          value=" BBA-CAT"
                        >
                          BBA-CAT
                        </option>

                        <option
                          className="form-control"
                          value="BBA-EDP"
                        >
                          BBA-EDP
                        </option>
                        <option
                          className="form-control"
                          value=" BBA-SAP"
                        >
                          BBA-SAP
                        </option>
                        <option
                          className="form-control"
                          value=" BBA-GME"
                        >
                          BBA-GME
                        </option>

                        <option
                          className="form-control"
                          value=" BBA-ACCA"
                        >
                          BBA-ACCA
                        </option>
                        <option
                          className="form-control"
                          value="  BBA-CWE"
                        >
                          BBA-CWE
                        </option>

                        <option
                          className="form-control"
                          value=" B.COM-COMPUTERS"
                        >
                          B.COM-COMPUTERS
                        </option>
                        <option
                          className="form-control"
                          value=" BBA Analytics"
                        >
                          BBA Analytics
                        </option>
                        <option
                          className="form-control"
                          value=" Food & Nutrition"
                        >
                          Food & Nutrition
                        </option>
                        <option
                          className="form-control"
                          value="Hotel Management"
                        >
                          Hotel Management
                        </option>
                      </select>

                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label className="form-label" htmlFor="signinPassword2"><span className='text-danger'>*</span>location</label>
                    <div className="input-group">
                      <div className="input-icon">
                        <i class="fa fa-location-arrow" aria-hidden="true"></i>
                      </div>
                      <input type="text" pattern="(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)" value={form.location} className="form-control" name="location" id="signinPassword2" onChange={(e) => { handlechange(e); }} placeholder=" location" aria-label="Password" required />
                    </div>
                  </div> */}
                  <div className="form-group">
                  </div>
                  <div className="form-group">
                    <button className="btn theme-btn btn-block mt-5" type='submit' >Submit</button>
                    <p className='visible1' onClick={handleClose}>.</p>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>

      </Modal>

      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>
  )
}

export default Header