import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "../assets/css/style.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { CardBody } from "reactstrap";
import per1 from "../assets/images/per1.png";
import per2 from "../assets/images/per2.png";
import per3 from "../assets/images/per3.png";
import per4 from "../assets/images/per4.png";
import per5 from "../assets/images/per5.png";

function Footer(props) {
  const navigate = useNavigate();
  const textInput = React.useRef();
  const textInput1 = React.useRef();
  const textInput2 = React.useRef();
  const textInput3 = React.useRef();

  const [form, setform] = useState([]);
  console.log(form);
  const clearInput = () => {
    textInput.current.value = "";
    textInput1.current.value = "";
    textInput2.current.value = "";
    textInput3.current.value = "";
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handlechange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const addUsers = () => {
    var params = {
      name: form.name,
      phone: form.phone,
      email: form.email,
      service: form.Courses,
      location: form.location
    };
    axios
      .post("https://api.icreatedegreecollege.com/api/user/leads/addLead", params, {})
      .then((res) => {
        console.log("success");
        toast.success(
          "Added Successfully. Your data received, Our team will get back to you soon!"
        );
        navigate('/thankyou')
      });
  };

  const usersSubmit = (e) => {
    e.preventDefault();
    addUsers();
    clearInput();
  };

  const settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    width: 500,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  // const [data16, setdata16] = useState();
  // console.log(data16)
  // const [data17, setdata17] = useState([props]);
  // console.log(data17)
  // const [data18, setdata18] = useState([props]);
  // console.log(data18)

  // console.log([props.data16])


  useEffect(() => {
    // datas();
  }, []);

  const api_url = "https://api.icreatedegreecollege.com";


  return (
    <div style={{ overflowY: "hidden" }}>
      <footer className="footerstyle">
        <div className="shape-top" />
        <div className="container">
          <div className="top-footer">
            <div className="row">
              <div className="col-md-3">
                <div className="footer-logo"></div>
                <div>
                  <h4 className="footer-title">Google reviews</h4>

                  <Slider {...settings1}>
                    <div>
                      <Row>
                        <Col md={4}>
                          <img src={per1} className="rounded-circle" style={{ width: '50px', height: '50px' }} />
                        </Col>
                        <Col md={8}>

                        </Col>
                      </Row>
                      <p>
                        One of fantastic college 🎉and management 👍👍best education ✍️

                      </p>
                      <div style={{ color: 'yellow  ' }}>
                        <i class="fa fa-star" aria-hidden="true" ></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <Row>
                        <Col md={4}>
                          <img src={per2} className="rounded-circle" style={{ width: '50px', height: '50px', marginBottom: '0px' }}></img>
                        </Col>
                        <Col md={8}>

                        </Col>
                      </Row>
                      <p>I create business school 🏫 is very helpful to my career feel really motivated when I am in Business School. The school has a very 💪strong motivating and performing 💯atmosphere and will help its students progress in their choosen career paths.and teacher are very good 💯 😊</p>
                      <div style={{ color: 'yellow  ' }}>
                        <i class="fa fa-star" aria-hidden="true" ></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <Row>
                        <Col md={4}>
                          <img src={per4} className="rounded-circle" style={{ width: '50px', height: '50px', marginBottom: '0px' }}></img>
                        </Col>
                        <Col md={8}>
                          {/* <h6 style={{ color: 'white' }}>Akhil Muthyala</h6> */}

                        </Col>
                      </Row>
                      <p>One of the finest college for commerce and management studies. This college literally shows how studies correlates with real time .Thank u to all the experienced faculties and the management for their support.I personally recommend to join my upcoming generation to choose a college like this.

                      </p>
                      <div style={{ color: 'yellow  ' }}>
                        <i class="fa fa-star" aria-hidden="true" ></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <Row>
                        <Col md={4}>
                          <img src={per3} className="rounded-circle" style={{ width: '50px', height: '50px', marginBottom: '0px' }}></img>
                        </Col>
                        <Col md={8}>

                        </Col>
                      </Row>
                      <p> feel really motivated when I am in Business School. The school has a very strong motivating and performing atmosphere and will help its students progress in their choosen career paths.

                      </p>
                      <div style={{ color: 'yellow  ' }}>
                        <i class="fa fa-star" aria-hidden="true" ></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div>
                      <Row>
                        <Col md={4}>
                          <img src={per5} className="rounded-circle" style={{ width: '50px', height: '50px', marginBottom: '0px' }}></img>
                        </Col>
                        <Col md={8}>
                        </Col>
                      </Row>
                      <p>Faculty is very supportive and they creates a positive vibes with their attention and inspire the students</p>
                      <div style={{ color: 'yellow  ' }}>
                        <i class="fa fa-star" aria-hidden="true" ></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </div>
                    </div>
                  </Slider>

                </div>
              </div>

              <div className="col-md-3">
                <h4 className="footer-title">icreate Placed</h4>

                <ul className="footer-links">
                  <marquee
                    class="marquee_info col-md-10 d-flex justify-content-center"
                    width="80%"
                    behavior="scroll"
                    direction="up"
                    scrollamount="2"
                    onmouseover="this.stop();"
                    onmouseout="this.start();"
                  >
                    {props.data17.map((data, i) => {
                      return (
                        <>

                          <div key={i} style={{ textAlign: 'center' }}  >

                            <img
                              src={api_url + "/" + data.image}
                              style={{ height: "40px", width: '80px' }}
                              className="mt-3"
                            />
                          </div>
                        </>
                      );
                    })}
                  </marquee>
                </ul>
              </div>

              <div className="col-md-3">
                <h4 className="footer-title"> {props.data18.title}</h4>
                <ul className="footer-links">
                  <li>
                    <a href="#">
                      <i
                        class="fa fa-map-marker mr-2"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      {props.data18.address}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i
                        class="fa fa-mobile mr-2"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      {props.data18.contactOne}
                      </a>
                      {/* <a href="#">
                      <i
                        class="fa fa-mobile mr-2"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      {props.data18.contactTwo}
                   </a> */}
                  </li>
                  <li>
                    <a href="#">
                      <i
                        class="fa fa-envelope-o mr-2"
                        aria-hidden="true"
                        style={{ fontSize: "20px" }}
                      ></i>
                      {props.data18.emailOne},<br></br>
                      {/* {props.data18.emailTwo} */}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-md-3">
                <h4 className="footer-title">Enquiry </h4>
                <ul className="footer-links">

                  <form onSubmit={usersSubmit}>
                    <div className="form-group">
                      {/* <label className="form-label"
                      htmlFor="signinEmail"
                    > <span className='text-danger'>*</span>Full Name</label> */}
                      <div className="input-group">
                        {/* <div className="input-icon">
                        <span className="fa fa-user-o" />
                      </div> */}
                        <input type="text" pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$" className="form-control" name="name" value={form.name} onChange={(e) => { handlechange(e); }} id="signinEmail" placeholder="Full Name" aria-label="Email address" required />
                      </div>
                    </div>
                    <div className="form-group">
                      {/* <label className="form-label" htmlFor="signinPassword">
                      <span className='text-danger'>*</span> Mobile
                    </label> */}
                      <div className="input-group">
                        {/* <div className="input-icon">
                        <span className="fa fa-mobile" />
                      </div> */}
                        <input type="number" minlength="0" maxlength="10" pattern="\d{10}" className="form-control" name="phone" value={form.phone} id="signinPassword" onChange={(e) => { handlechange(e); }} placeholder="Mobile No..." aria-label="Password" required />
                      </div>
                    </div>
                    <div className="form-group">
                      {/* <label className="form-label" htmlFor="signinPassword2"> Email Id</label> */}
                      <div className="input-group">
                        {/* <div className="input-icon">
                        <span className="fa fa-envelope-o" />
                      </div> */}
                        <input type="email" pattern="(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)" className="form-control" name="email" value={form.email} id="signinPassword2" onChange={(e) => { handlechange(e); }} placeholder="Email Id" aria-label="Password" required />
                      </div>
                    </div>
                    <div className="form-group">
                      {/* <label className="form-label" htmlFor="signinPassword2"><span className='text-danger'>*</span>Course </label> */}
                      <div className="input-group">
                        <div className="input-icon">
                          {/* <span className="fa fa-globe" /> */}
                          {/* <i class="fa fa-graduation-cap" aria-hidden="true"></i> */}
                        </div>
                        <div className="input-group">
                          <select className="form-control" name="Courses" value={form.Courses} id="signinPassword2" placeholder="Email Id" onChange={(e) => { handlechange(e); }} aria-label="Password" required >
                            <option value="">Select Course</option>
                            <option
                              className="form-control"
                              value="BBA"
                            >
                              BBA
                            </option>

                            <option
                              className="form-control"
                              value=" BBA-CAT"
                            >
                              BBA-CAT
                            </option>

                            <option
                              className="form-control"
                              value="BBA-EDP"
                            >
                              BBA-EDP
                            </option>
                            <option
                              className="form-control"
                              value=" BBA-SAP"
                            >
                              BBA-SAP
                            </option>
                            <option
                              className="form-control"
                              value=" BBA-GME"
                            >
                              BBA-GME
                            </option>

                            <option
                              className="form-control"
                              value=" BBA-ACCA"
                            >
                              BBA-ACCA
                            </option>
                            <option
                              className="form-control"
                              value="  BBA-CWE"
                            >
                              BBA-CWE
                            </option>

                            <option
                              className="form-control"
                              value=" B.COM-COMPUTERS"
                            >
                              B.COM-COMPUTERS
                            </option>
                            <option
                              className="form-control"
                              value=" BBA Analytics"
                            >
                              BBA Analytics
                            </option>
                            <option
                              className="form-control"
                              value=" Food & Nutrition"
                            >
                              Food & Nutrition
                            </option>
                            <option
                              className="form-control"
                              value="Hotel Management"
                            >
                              Hotel Management
                            </option>
                          </select>

                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      {/* <label className="form-label" htmlFor="signinPassword2"><span className='text-danger'>*</span>location</label> */}
                      <div className="input-group">
                        {/* <div className="input-icon">
                      <i class="fa fa-location-arrow" aria-hidden="true"></i>
                      </div> */}
                        <input type="text" pattern="(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)" className="form-control" name="location" value={form.location} id="signinPassword2" onChange={(e) => { handlechange(e); }} placeholder=" location" aria-label="Password" required />
                      </div>
                    </div>
                    <div className="form-group">
                    </div>
                    <div className="form-group">
                      <button className="btn theme-btn btn-block" type='submit'>Submit</button>

                    </div>
                  </form>
                </ul>
              </div>
            </div>
          </div>

          <div className="copyrights">
            <p>
            Copyright © 2024 icreate All Rights Reserved - Designed by <a href="https://digitalraiz.com/" style={{color:'#d76170'}}>DigitalRaiz</a>
            </p>
          </div>
        </div>
      </footer>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}

export default Footer;
