import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import icreatelogo from "../assets/images/icreatelogo.png";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import axios from "axios";

const Login = () => {
  const [admin, setadmin] = useState({ email: "", password: "" });
  let navigate = useNavigate();
  const handleChange = (e) => {
    let newadmin = { ...admin };
    newadmin[e.target.name] = e.target.value;
    setadmin(newadmin);
  };

  const addAdmin = () => {
    var paras = {
      email: admin.email,
      password: admin.password,
    };

    axios
      .post(
        "https://api.icreatedegreecollege.com/api/admin/icreate/usersignin",
        paras
      )
      .then(
        (res) => {
          if (res.status === 200) {
            console.log(res.data);
            sessionStorage.setItem("AdminId", res.data.user._id);
            sessionStorage.setItem("email", res.data.user.email);
            sessionStorage.setItem("role", res.data.user.role);
            sessionStorage.setItem("token", res.data.token);
            window.location.href = "/admin";
            // navigate('/admin')
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };
  const usersSubmit = (e) => {
    e.preventDefault();
    addAdmin();
  };

  return (
    <>
      <div style={{ paddingTop: "120px" }}>
        <div className="row">
          <div className="login-page">
            <div className="col-md-4 offset-md-4">
              <div className="card cardstyle">
                <a className="res-logo"></a>
                <div
                  className="login-form"
                  style={{ width: "80%", padding: "30px" }}
                >
                  <div className="login-form-head d-flex justify-content-center">
                    <img src={icreatelogo} alt="Logo" width={200} />
                  </div>
                  <form onSubmit={usersSubmit}>
                    <div className="form-group mt-3">
                      <label className="form-label" htmlFor="signinEmail ">
                        Email address
                      </label>
                      <div className="input-group">
                        <div className="input-icon">
                          <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        </div>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email address"
                          value={admin.email}
                          autocomplete="off"                          
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="signinPassword">
                        Password
                      </label>
                      <div className="input-group">
                        <div className="input-icon">
                          <i class="fa fa-unlock" aria-hidden="true"></i>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="********"
                          aria-label="Password"
                          autocomplete="off"
                          value={admin.password}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div style={{ float: "right" }}>
                      <span className="d-flex justify-content-between  mb-2"></span>
                    </div>
                    <div className="form-group">
                      <button className="btn theme-btn btn-block" type="submit">
                        Login
                      </button>
                    </div>
                    <div className="form-group login-desc"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Login;
