import React from 'react'
import "../Css/common.css";
import facebook from "../assets/images/newimgs/facebook.png";
import insta from "../assets/images/newimgs/insta1.png";
import linkedin from "../assets/images/newimgs/linkedin.png";
import twitter from "../assets/images/newimgs/twitter.png";
import youtube from "../assets/images/youtube.png";

function Sidebar() {
  return (
    <div>
       <div>
       <div class="menu-container">
        <div class="collapse-icon">
          <span class="social_media_text"></span>
          {/* <span class="fa fa-chevron-right"></span> */}
        </div>
        <div class="menu-item">
          <a href="https://www.facebook.com/Icreate12" target="_blank" style={{padding: "0px"}}>
            <img src={facebook} alt="" />
            {/* <div class="menu-item-text">Facebook</div> */}
          </a>
        </div>
        <div class="menu-item">
          <a href="https://twitter.com/icreateedu1" target="_blank" style={{padding: "0px"}}>
            <img src={twitter} alt="" />
            {/* <div class="menu-item-text">Twitter</div> */}
          </a>
        </div>
       
        <div class="menu-item">
          <a href=" https://www.linkedin.com/in/icreate-business-7329261a4/" target="_blank" style={{padding: "0px"}}>
            <img src={linkedin} alt="" />
            {/* <div class="menu-item-text">Linkedin</div> */}
          </a>
        </div>
        <div class="menu-item">
          <a href=" https://www.instagram.com/icreate12/" target="_blank" style={{padding: "0px"}}>
            <img src={insta} alt="" />
            {/* <div class="menu-item-text">Youtube</div> */}
          </a>
        </div>
        <div class="menu-item">
          <a href=" https://www.youtube.com/@icreatebusinessschool276/videos" target="_blank" style={{padding: "0px"}}>
            <img src={youtube} alt="" style={{height:"25px"}} />
            {/* <div class="menu-item-text">Youtube</div> */}
          </a>
        </div>
      </div>
       </div>
    </div>
  )
}

export default Sidebar