import React, { useState, useEffect } from "react";
import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Row, Col, Card, CardBody } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";

function About() {
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  useEffect(() => {
    getCategory1();
  }, []);

  const [users, setusers] = useState([]);

  const handleChanges = (e) => {
    let myUser = { ...users };
    myUser[e.target.name] = e.target.value;
    setusers(myUser);
  };

  const getCategory1 = () => {
    var token = sessionStorage.getItem("token");
    axios
      .post(
        "https://api.icreatedegreecollege.com/api/admin/footprint/getfootp",
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setusers(res.data.footResult);
      });
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    updateCategory1();
  };

  const updateCategory1 = () => {
    const data1 = users._id;
    var token = sessionStorage.getItem("token");

    const data = {
      title: users.title,
      description: users.description,
      subtitleOne: users.subtitleOne,
      subDescriptionOne: users.subDescriptionOne,
      subtitleTwo: users.subtitleTwo,
      subDescriptionTwo: users.subDescriptionTwo,
      subtitleThree: users.subtitleThree,
      subDescriptionThree: users.subDescriptionThree,
      subtitleFour:users.subtitleFour,
      subDescriptionFour:users.subDescriptionFour
    };

    axios
      .put(
        "https://api.icreatedegreecollege.com/api/admin/footprint/editfootp" +
        "/" +
        data1,
        data,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose2();
            getCategory1();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      <Box
        sx={{ display: "flex" }}
        style={{ background: "#ebeaea", padding: "111px 0 0 0" }}
        className="cardmrg"
      >
        <Drawer></Drawer>
        <CssBaseline />
        <Box
          component="main"
          style={{ background: "#ebeaea" }}
          sx={{ flexGrow: 2, p: 4 }}
        >
          {" "}
          <Card className="cardstyle">
            {" "}
            <CardBody>
              {" "}
              <div className="row">
                <div className="col">
                  <h5>About Content</h5>
                </div>
                <div className="col">
                  <span style={{ float: "right" }}>
                    {" "}
                    <button
                      className="btn btn filter mb-1"
                      onClick={handleShow2}
                    >
                      {" "}
                      + Edit
                    </button>
                  </span>
                </div>
              </div>
              <Row className="mt-5">
                <Col md={4} style={{ textAlign: "center" }}>
                  Title:
                </Col>
                <Col md={8}>
                  <p>{users.title}</p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4} style={{ textAlign: "center" }}>
                  Description:
                </Col>
                <Col md={8}>
                  <p>{users.description}</p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4} style={{ textAlign: "center" }}>
                  Subtitle One:
                </Col>

                <Col md={8}>
                  <p>{users.subtitleOne}</p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4} style={{ textAlign: "center" }}>
                  Sub Description One:
                </Col>
                <Col md={8}>
                  <p>{users.subDescriptionOne}</p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4} style={{ textAlign: "center" }}>
                  Subtitle Two:
                </Col>
                <Col md={8}>
                  <p>{users.subtitleTwo}</p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4} style={{ textAlign: "center" }}>
                  Sub Description Two:
                </Col>
                <Col md={8}>
                  <p>{users.subDescriptionTwo}</p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4} style={{ textAlign: "center" }}>
                  Subtitle Three:
                </Col>

                <Col md={8}>
                  <p>{users.subtitleThree}</p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4} style={{ textAlign: "center" }}>
                  Sub Description Three:
                </Col>

                <Col md={8}>
                  <p>{users.subDescriptionThree}</p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4} style={{ textAlign: "center" }}>
                  Subtitle Four:
                </Col>

                <Col md={8}>
                  <p>{users.subtitleFour}</p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4} style={{ textAlign: "center" }}>
                  Sub Description Four:
                </Col>

                <Col md={8}>
                  <p>{users.subDescriptionFour}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Box>
      </Box>
      <div>
        <Modal show={show2} onHide={handleClose2} style={{ marginTop: "80px" }}>
          <Modal.Header>
            <Modal.Title>
              <h3>Edit</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              onSubmit={(e) => {
                handleSubmit2(e);
              }}
            >
              <div class="container">
                <label>Title: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter title"
                  required
                  name="title"
                  value={users.title}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                />

                <label className="mt-3">Description :</label>
                <textarea
                  class="form-control uuu"
                  id="exampleFormControlTextarea3 "
                  rows="3"
                  name="description"
                  required
                  value={users.description}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                ></textarea>

                <label>Subtitle One: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter title"
                  required
                  name="subtitleOne"
                  value={users.subtitleOne}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                />

                <label className="mt-3">subDescriptionOne :</label>
                <textarea
                  class="form-control uuu"
                  id="exampleFormControlTextarea3 "
                  rows="3"
                  name="subDescriptionOne"
                  required
                  value={users.subDescriptionOne}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                ></textarea>

                <label>Subtitle Two: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter title"
                  required
                  name="subtitleTwo"
                  value={users.subtitleTwo}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                />

                <label className="mt-3">Sub Description Two :</label>
                <textarea
                  class="form-control uuu"
                  id="exampleFormControlTextarea3 "
                  rows="3"
                  name="subDescriptionTwo"
                  required
                  value={users.subDescriptionTwo}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                ></textarea>

                <label>Subtitle Three: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter title"
                  required
                  name="subtitleThree"
                  value={users.subtitleThree}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                />

                <label className="mt-3">Sub Description Three :</label>
                <textarea
                  class="form-control uuu"
                  id="exampleFormControlTextarea3 "
                  rows="3"
                  name="subDescriptionThree"
                  required
                  value={users.subDescriptionThree}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                ></textarea>
                <label>Subtitle Four: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter title"
                  required
                  name="subtitlefour"
                  value={users.subtitleFour}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                />

                <label className="mt-3">Sub Description Four :</label>
                <textarea
                  class="form-control uuu"
                  id="exampleFormControlTextarea3 "
                  rows="3"
                  name="subDescriptionfour"
                  required
                  value={users.subDescriptionFour}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                ></textarea>
                <div class="text-center mt-3 float-right">
                  <button
                    style={{ margin: "0px 0px 0px 40px", color: "white" }}
                    type="button"
                    class="btn btn-sm btn-danger mr-3"
                    onClick={handleClose2}
                  >
                    <i class="fa fa-times-circle"></i>
                    <span aria-hidden="true"> Cancel</span>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-success save"
                    style={{ color: "white" }}
                  >
                    <i class="fa fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </div>
    </div>
  );
}

export default About;
