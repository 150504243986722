import React, { useState, useEffect } from "react";
import Drawer from "./Drawers";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Table } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";

function Administrations() {
  const [form, setform] = useState({});
  const [Files, setFiles] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  useEffect(() => {
    getCategory1();
    getCategory2();
  }, []);

  const changeHandler = (e) => {
    setFiles(e.target.files);
  };

  const api_url = "https://api.icreatedegreecollege.com";

  const [users, setusers] = useState([]);

  const handleChanges = (e) => {
    let myUser = { ...users };
    myUser[e.target.name] = e.target.value;
    setusers(myUser);
  };

  const getCategory1 = () => {
    var token = sessionStorage.getItem("token");
    axios
      .post(
        "https://api.icreatedegreecollege.com/api/admin/hiredev/gethire",
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setusers(res.data.hireResult);
      });
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    updateCategory1();
  };

  const updateCategory1 = () => {
    const data1 = users._id;
    var token = sessionStorage.getItem("token");

    const dataArray = new FormData();
    dataArray.append("title", users.title);
    dataArray.append("description", users.description);
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("hireImg", Files[i]);
    }

    axios
      .put(
        "https://api.icreatedegreecollege.com/api/admin/hiredev/edithire" + "/" + data1,
        dataArray,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose2();
            getCategory1();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [users2, setusers2] = useState([]);

  const handleChanges2 = (e) => {
    let myUser = { ...users2 };
    myUser[e.target.name] = e.target.value;
    setusers2(myUser);
  };

  const getCategory2 = () => {
    var token = sessionStorage.getItem("token");
    axios
      .post(
        "https://api.icreatedegreecollege.com/api/admin/indsol/getindsol",
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setusers2(res.data.indusResult);
      });
  };

  const handleSubmit3 = (e) => {
    e.preventDefault();
    updateCategory2();
  };

  const updateCategory2 = () => {
    const data1 = users2._id;
    var token = sessionStorage.getItem("token");

    const data = { description: users2.description, title: users2.title };
    axios
      .put(
        "https://api.icreatedegreecollege.com/api/admin/indsol/editindsol" + "/" + data1,
        data,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose3();
            getCategory2();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      <Box
        sx={{ display: "flex" }}
        style={{ background: "#ebeaea", padding: "111px 0 0 0" }}
        className="cardmrg"
      >
        <Drawer></Drawer>
        <CssBaseline />
        <Box
          component="main"
          style={{ background: "#ebeaea" }}
          sx={{ flexGrow: 2, p: 4 }}
        >
          {" "}
          <Card className="cardstyle">
            {" "}
            <CardBody>
              {" "}
              <div className="row">
                <div className="col">
                  <h5>Hire</h5>
                </div>
                <div className="col">
                  <span style={{ float: "right" }}>
                    {" "}
                    <button
                      className="btn btn filter mb-1"
                      onClick={handleShow2}
                    >
                      {" "}
                      + Edit
                    </button>
                  </span>
                </div>
              </div>
              <Row className="mt-5">
                <Col md={2} style={{ textAlign: "center" }}>
                  Title:
                </Col>

                <Col md={10}>
                  <p>{users.title}</p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={2} style={{ textAlign: "center" }}>
                  Description:
                </Col>

                <Col md={10}>
                  <p>{users.description}</p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={2} style={{ textAlign: "center" }}>
                  Image:
                </Col>

                <Col md={10}>
                  <img
                    src={api_url + "/" + users.image}
                    style={{
                      width: "100px",
                      cursor: "pointer",
                    }}
                  ></img>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {/* <Card className="cardstyle">
            {" "}
            <CardBody>
              {" "}
              <div className="row">
                <div className="col">
                  <h5>Didn't Find Your Industry Solution123</h5>
                </div>
                <div className="col">
                  <span style={{ float: "right" }}>
                    {" "}
                    <button
                      className="btn btn filter mb-1"
                      onClick={handleShow3}
                    >
                      {" "}
                      + Edit
                    </button>
                  </span>
                </div>
              </div>
              <Row className="mt-5">
                <Col md={2} style={{ textAlign: "center" }}>
                  Title:
                </Col>

                <Col md={10}>
                  <p>{users2.title}</p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={2} style={{ textAlign: "center" }}>
                  Description:
                </Col>

                <Col md={10}>
                  <p>{users2.description}</p>
                </Col>
              </Row>

            </CardBody>
          </Card> */}


        </Box>
      </Box>
      <div>
        <Modal show={show2} onHide={handleClose2} style={{ marginTop: "80px" }}>
          <Modal.Header>
            <Modal.Title>
              <h3>Edit</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              onSubmit={(e) => {
                handleSubmit2(e);
              }}
            >
              <div class="container">
                <label>Title: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter title"
                  required
                  name="title"
                  value={users.title}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                />
                <label>Image :</label>
                <br></br>
                <span style={{ fontSize: "10px", color: "red" }}>
                  Size :	540 × 293 px
                </span>
                <input
                  type="file"
                  className="form-control uuu"
                  name="image"
                  multiple
                  onChange={changeHandler}
                />

                <label className="mt-3">Description :</label>
                <textarea
                  class="form-control uuu"
                  id="exampleFormControlTextarea3 "
                  rows="3"
                  name="description"
                  required
                  value={users.description}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                ></textarea>

                <div class="text-center mt-3 float-right">
                  <button
                    style={{ margin: "0px 0px 0px 40px", color: "white" }}
                    type="button"
                    class="btn btn-sm btn-danger mr-3"
                    onClick={handleClose2}
                  >
                    <i class="fa fa-times-circle"></i>
                    <span aria-hidden="true"> Cancel</span>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-success save"
                    style={{ color: "white" }}
                  >
                    <i class="fa fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* <Modal show={show3} onHide={handleClose3} style={{ marginTop: "80px" }}>
          <Modal.Header>
            <Modal.Title>
              <h3>Edit</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              method="post"
              onSubmit={(e) => {
                handleSubmit3(e);
              }}
            >
              <div class="container">
                <label>Title: </label>
                <input
                  type="text"
                  className="form-control uuu"
                  placeholder="Enter title"
                  required
                  name="title"
                  value={users2.title}
                  onChange={(e) => {
                    handleChanges2(e);
                  }}
                />

                <label className="mt-3">Description :</label>
                <textarea
                  class="form-control uuu"
                  id="exampleFormControlTextarea3 "
                  rows="3"
                  name="description"
                  required
                  value={users2.description}
                  onChange={(e) => {
                    handleChanges2(e);
                  }}
                ></textarea>

                <div class="text-center mt-3 float-right">
                  <button
                    style={{ margin: "0px 0px 0px 40px", color: "white" }}
                    type="button"
                    class="btn btn-sm btn-danger mr-3"
                    onClick={handleClose3}
                  >
                    <i class="fa fa-times-circle"></i>
                    <span aria-hidden="true"> Cancel</span>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm btn-success save"
                    style={{ color: "white" }}
                  >
                    <i class="fa fa-check-circle"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal> */}
        <ToastContainer />
      </div>
    </div>
  );
}

export default Administrations;
