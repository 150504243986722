import React from 'react'

function Whatsapp() {
  return (
    <div>
        <div style={{ float: "right" }}>
        <div className='menu-container1'>
          {/* <img src={whatsapp}/> */}
          <a href="https://web.whatsapp.com/send?phone=918367215215&amp;text=" target="_blank" class="sbutton whatsapp " tooltip="WhatsApp">
            <i class="fa fa-whatsapp whatsappicon"></i>
            </a>
        </div>
      </div>
    </div>
  )
}

export default Whatsapp